.dots-wrap {
  --_navigation-dots-offset: var(--navigation-dots-offset, 5%);
  --_navigation-dots-offset-calc: calc(var(--_navigation-dots-offset) + 16px);

  /* `--navigation-dots-offset` is set at template level. 
     Unfortunately this % is based on width (eg margin-top: 4%, is 4% of parent width, not height). 
     Each component uses some magic numbers, change between mobile/desktop
     */

  display: flex;
  justify-content: center;
  width: 100%;
}

.dots-overflow {
  --c-focus-ring-size: 4px;

  position: absolute;
  top: 0;
  box-sizing: content-box; /* padding is used to stop focus ring getting cut off by `overflow: hidden`, this makes width calculations easier so we don't need to worry about the padding */
  margin-top: calc(
    var(--_navigation-dots-offset-calc) - var(--c-focus-ring-size)
  );
  padding: var(--c-focus-ring-size);
  width: var(--js-dots-width); /* set via JS in the template */
  height: 16px;
  overflow: hidden;
}

.dots {
  --c-dot-color: var(--color-surface-solid-dark-lighter);

  display: flex;
  flex-wrap: no-wrap; /* Enforce overflow effect */
  justify-content: flex-start;
  gap: 16px;
  transition: all 0.2s linear;

  /* value comes from CSS var set in template, to move dots outside of masked area. RTL transform support */
  margin-inline-start: calc(var(--js-dots-transform, 0) * 1px);
}

button.dot {
  all: unset;
  cursor: pointer;
}

button[disabled].dot {
  cursor: unset;
}

.dot,
button.dot {
  position: relative;
  flex-shrink: 0; /* stop dots compressing */
  transition: all 0.2s linear;
  border-radius: var(--radius-x-large);
  background-color: color-mix(in srgb, var(--c-dot-color), transparent 75%);
  width: 16px;
  height: 16px;
}

button.dot:focus-visible {
  outline-color: var(--color-focus);
  outline-style: var(--button-outline-style);
  outline-width: var(--button-outline-width);
  outline-offset: var(--button-outline-offset);
}

/* If an interactive hero has been added to the page, change colours */
[data-has-custom-theme] .dot,
[data-has-custom-theme] button.dot {
  --c-dot-color: var(
    --page-theme-contrast-color,
    var(--color-surface-solid-dark-lighter)
  );
}

/* this centers the dots when only a few dots, but doesn't center align the dots when there are many dots */
.dot:first-child,
button.dot:first-child {
  margin-inline-start: auto; /* RTL support */
}

.dot:last-child,
button.dot:last-child {
  margin-inline-end: auto; /* RTL support */
}

.dot--is-visible:first-child,
.dot--is-visible:last-child,
button.dot--is-visible:first-child,
button.dot--is-visible:last-child {
  transform: scale(1);
}

.dot--is-active,
button:not([disabled]).dot:hover,
button.dot--is-active {
  background-color: color-mix(in srgb, var(--c-dot-color), transparent 25%);
}

.dot--is-prev,
.dot--is-next,
button.dot--is-prev,
button.dot--is-next {
  transform: scale(0.75);
}

/* Visually Hidden, sr-only */
.dot-label {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  white-space: nowrap;
}
