.container {
  /* The ratio of each card's size, relative to the card below it in the stack */
  --_card-stack-top-card-scale: var(--card-stack-top-card-scale, 1.1);
  --_card-stack-card-scaling-factor: var(--card-stack-card-scaling-factor, 10);
  --_card-stack-card-spacing-factor: var(--card-stack-card-spacing-factor, 2);
  --_card-stack-cards-wrapper-max-width: var(--card-stack-max-width, 343px);

  margin: 0 auto;
  width: 100%;
  max-width: var(--_card-stack-cards-wrapper-max-width);
  height: auto;
}

.container--box-information-highlight {
  --_card-stack-card-spacing-factor: var(--card-stack-card-spacing-factor, 4);
}

.container--with-hero {
  height: 473px; /* TODO: this is based on the original code, it was on the main card stack but forcing that height didn't work with all applications */
}

@media (width >= 650px) {
  .container {
    --_card-stack-cards-wrapper-max-width: var(--card-stack-max-width, 384px);

    height: auto;
  }

  .container--with-hero {
    height: auto;
  }
}

.cards-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  margin-inline: auto;
  margin-bottom: 76px;
  width: 100%;

  &.cards-wrapper--vertical {
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
  }
  .container--box-information-highlight & {
    margin-bottom: initial;
  }
}

.cards-stage {
  display: grid;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Hack: needed for some components to display correctly */
}

.card-container {
  display: flex;
  grid-row-start: 1;
  grid-column-start: 1;
  transform-origin: bottom;
  visibility: visible;
  transition: all 0.25s ease-in-out;
  will-change: transform, opacity;
  width: 100%;
  height: 100%;

  .cards-wrapper--vertical & {
    margin-bottom: 30px;
  }
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  z-index: 1;
  transition: opacity 0.25s ease-in;

  @media (hover: none) {
    display: none;
  }
}

/* Used for when used in BoxInformationHighlight */
.container--box-information-highlight .slider-button {
  --cosmos-button-color: var(--color-surface-solid-dark-darker);
  --cosmos-button-background: var(--color-surface-glass-dark-10);
  --cosmos-button-border-color: transparent;
  --cosmos-button-color-hover: var(--color-surface-solid-light-lighter);
  --cosmos-button-background-hover: var(--color-surface-solid-dark);
  --cosmos-button-border-width: 1px;
  --cosmos-button-border-color-hover: var(--color-surface-solid-dark-lighter);

  position: relative;
  transform: none;
  opacity: 1;

  @media (hover: none) {
    display: initial;
  }
}

@media (hover: hover) {
  .cards-wrapper:hover .slider-button {
    opacity: 1;
  }
}

.slider-button--prev {
  inset-inline-start: 0; /* RTL support */
}

.slider-button--next {
  inset-inline-end: 0; /* RTL support */
}

/* Used in athlete card stack - only new buttons for > tablet */
@media (width >= 650px) {
  .container--wide .slider-button {
    --cosmos-button-color: var(--color-surface-solid-light-lighter);
    --cosmos-button-color-hover: var(--color-surface-solid-light-lighter);
    --cosmos-button-background: var(--color-surface-solid-dark);
    --cosmos-button-background-hover: var(--color-surface-solid-dark);
    --cosmos-button-border-width: 1px;
    --cosmos-button-border-color: var(--color-surface-solid-dark-lighter);
    --cosmos-button-border-color-hover: var(--color-surface-solid-dark-lighter);

    transform: none;
    opacity: 0.7;
  }
}

html[dir="rtl"] .slider-button {
  transform: scaleX(-1); /* Chevron icon flipped for RTL */
}

.navigation {
  position: absolute;
  top: 0;
  right: -65px;
  bottom: 0;
  left: -65px;
  z-index: -1; /* stop this div blocking touch/mouse events */
}

/* Used for Athlete quote stack, only for > tablet */
@media (width >= 650px) {
  .container--wide .navigation {
    top: auto;
    right: 43px;
    bottom: 53px;
    left: auto;
    z-index: unset;
  }
}

/* Used for when used in BoxInformationHighlight */
.container--box-information-highlight .navigation {
  position: initial;
  justify-items: center;
  z-index: unset;
  margin-top: 20px; /*magic number */
}

.slider-button-wrapper {
  position: relative;
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.3, 0, 0.2, 1);
  width: 100%;
  height: 100%;

  .container--wide & {
    z-index: 100;
  }
}

@media (width >= 650px) {
  .container--wide .slider-button-wrapper {
    display: flex;
    gap: var(--slider-button-wrapper-gap);
  }

  .container--wide .slider-button {
    position: relative;
  }
}

.container--box-information-highlight .slider-button-wrapper {
  display: flex;
  gap: 20px; /* magic number */
  z-index: 100;
  margin-top: 20px; /* magic number */
  width: unset;
  height: unset;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dots {
  position: absolute;
  bottom: 0;
}
